import { Link } from "react-router-dom";
import Nav from "./components/Nav";
const App = () => {
  return (
    <div className="container" style={{padding: '30px'}}>
      <div style={{margin: '20px'}}>
        <Link style={{padding: '10px', margin: '80px', color: 'gray'}} to="/">
          One tap login
        </Link>
        <Link style={{padding: '10px', margin: '80px', color: 'gray'}} to="google">
          Google login
        </Link>
      </div>
      <div>
        <h3>
          <Nav />
        </h3>
      </div>
    </div>
  );
};
export default App;