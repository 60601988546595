import './Home.css'
import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google';
// import Parse from 'parse';
import { useState } from 'react';
import Parse from 'parse'
import React from 'react'
import { GoogleLogin } from '@react-oauth/google';

export function Home() {
    const [cred, setCred] = useState("");
    const [google1, setGoogle1] = useState("")
    const [google2, setGoogle2] = useState("")

    const [loading, setLoading] = React.useState(false)

    const generateGoogleLoginUrl = async () => {
        setLoading(true)
        const result = await Parse.Cloud.run('generate-google-login-uri', {
            redirect_uri: 'https://google-login-6n3.pages.dev'
        })
        console.log(result)
        window.location.href = result
    }

    const googleLogin = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code'); // Retrieve the authorization code from URL parameters

        const result = await Parse.Cloud.run('google-login', {
            code: code,
            redirect_uri: 'https://google-login-6n3.pages.dev'
        })
        console.log(result)
    }

    // useGoogleOneTapLogin({
    //     onSuccess: credentialResponse => {
    //         console.log(credentialResponse);
    //         Parse.Cloud.run('one-tap-google-login', {
    //             id_token: credentialResponse.credential,
    //             redirect_uri: 'https://google-login-6n3.pages.dev'
    //         }).then((result) => {
    //             console.log(result)
    //         })

    //     },
    //     onError: () => {
    //         console.log('Login Failed');
    //     },
    // })

    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            setCred(credentialResponse.credential);
            console.log(credentialResponse);
            const id_token = credentialResponse.credential;
            Parse.Cloud.run('google-login', {
                id_token: id_token,
                redirect_uri: 'https://google-login-6n3.pages.dev'
            }).then((result) => {
               const sessionToken = result.get('sessionToken')
                Parse.User.become(sessionToken).then((user) => {
                    console.log(user);
                    const email = user.get('email');
                    setGoogle1(email);
                });
            }
            )

        },
        onError: () => {
            console.log('Login Failed');
        },
    })

    const responseMessage = (response) => {
        console.log(response);
        const id_token = response.credential;
        Parse.Cloud.run('google-login', {
            id_token: id_token,
            redirect_uri: 'https://google-login-6n3.pages.dev'
        }).then((result) => {
           const sessionToken = result.get('sessionToken')
            Parse.User.become(sessionToken).then((user) => {
                console.log(user);
                const email = user.get('email');
                setGoogle2(email);
            });
        }
    )
    };
    const errorMessage = (error) => {
        console.log(error);
    }

    const login = useGoogleLogin({
        onSuccess: codeResponse => console.log(codeResponse),
        flow: 'auth-code',
    });

    return (
        <>
            <h1>one tap login</h1>
            {
                google1 && <p> logged in with : {google1}</p>
            }
           
            {/* <p>id_token:   {cred}</p> */}
            {/* <button style={{ margin: '7px', padding: '5px' }} onClick={googleLogin}>googleLogin</button> */}
            <div>

                {/* <button style={{ margin: '7px', padding: '5px' }} disabled={loading} onClick={generateGoogleLoginUrl}>
                    {
                        loading ? 'Loding...' : 'Generate Google Login URL'
                    }
                </button><br /> */}
                {/* <button style={{ margin: '7px', padding: '5px' }} onClick={googleLogin}>googleLogin</button><br /> */}

                <br /> <br /> <br />
                <br /> <br /> <br />
            </div>


            <div>
                <h2>Google Login</h2>
                <br />
                <br />
                <GoogleLogin
                    type='standard'
                    width={200}
                    onSuccess={responseMessage} onError={errorMessage} />
            </div>
            {
                google2 && <p>logged in with: {google2}</p>
            }
            {/* <button onClick={login}>Login</button> */}
        </>
    )
}