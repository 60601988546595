import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Parse from 'parse';
import { GoogleOAuthProvider } from '@react-oauth/google';

Parse.initialize(process.env.REACT_APP_PARSE_APP_ID, process.env.REACT_APP_PARSE_JAVASCRIPT_KEY);
Parse.serverURL = process.env.REACT_APP_PARSE_SERVER_URL;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId="124216467288-d4pca5a9d5vqte58tq38f4k5an6q42lg.apps.googleusercontent.com">
    <React.StrictMode>
      <BrowserRouter basename="/">
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </GoogleOAuthProvider>,
);